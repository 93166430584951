<template>
  <div>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-11">
              <h1><b-icon icon="people" /> Integrantes</h1>
            </div>
            <div class="col-1">
              <h1>
                <b-icon
                  type="button"
                  @click="toPrograms"
                  icon="arrow-return-left"
                />
              </h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="container">
            <b-row>
              <b-col></b-col>
              <b-col class="mb-3">
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input-group-prepend is-text>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="keyword"
                    placeholder="Buscar"
                    type="text"
                  />
                </b-input-group>
              </b-col>
            </b-row>
          </div>
          <div class="container col-12">
            <div>
              <b-table
                striped
                responsive
                stacked="lg"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :keyword="keyword"
              >
                <template #cell(names)="row">
                  <p>
                    {{
                      row.item.lastnameFather +
                      " " +
                      row.item.lastnameMother +
                      ", " +
                      row.item.name
                    }}
                  </p>
                </template>
                <template #cell(enrollmentDateRegister)="row">
                  <p v-text="fecha(row.item.enrollmentDateRegister)" />
                </template>
              </b-table>
              <b-pagination
                style="display: flex; justify-content: center"
                v-model="currentPage"
                pills
                :total-rows="items.length"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    idProgram: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    keyword: "",
    perPage: 10,
    currentPage: 1,
    dataDelete: "",
    showDelete: false,
    identifier: null,
    name: "",
    data: {},
    fields: [
      { key: "names", label: "Apellidos, Nombres" },
      { key: "groupTeaching", label: "Turno" },
      { key: "enrollmentDateRegister", label: "Fecha y hora de registro" },
    ],
    desserts: [],
  }),

  created() {
    this.initialize();
  },
  computed: {
    rows() {
      return this.desserts.length;
    },
    items() {
      this.currentPage = 1;
      return this.keyword
        ? this.desserts.filter(
            (item) =>
              item.name.toLowerCase().includes(this.keyword.toLowerCase()) ||
              item.lastnameFather
                .toLowerCase()
                .includes(this.keyword.toLowerCase()) ||
              item.lastnameMother
                .toString()
                .toLowerCase()
                .includes(this.keyword.toLowerCase()) ||
              item.groupTeaching
                .toLowerCase()
                .includes(this.keyword.toLowerCase()) ||
              item.enrollmentDateRegister
                .toString()
                .toLowerCase()
                .includes(this.keyword.toLowerCase())
          )
        : this.desserts;
    },
  },

  methods: {
    fecha(date) {
      return date.substring(0, 10) + " (" + date.substring(11, 16) + ")";
    },
    toPrograms() {
      this.$router.go(-1);
    },
    async initialize() {
      await this.$axios
        .get("/academic/" + this.id + "/" + this.idProgram + "/enrolleds", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>